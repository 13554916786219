import React from 'react';
import { injectIntl } from 'react-intl';
import AuthMessage from '../authentication/AuthMessage';

const Mobile = props => (
  <AuthMessage
    text={props.intl.formatMessage({ id: 'BlockMobile' })}
    withLoginButton={false}
  />
);

export default injectIntl(Mobile);
