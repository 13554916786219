// const domain = 'hourone.auth0.com';
const domain = 'login.hourone.ai';
// const clientId = '50Hphu2SN8rsS7xtjoCx0UbRNPjX2eiZ';
const clientId = 'c0xqJrt6rYAr8nTeQqA3j2wP370I6oMj';
//
let callbackUrl = 'https://integration.hourone.ai/callback';
if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
	callbackUrl = 'http://localhost:3000/callback';
}
//
const apiUrl = 'https://prompt.hourone.ai';
const responseType = 'token id_token';
const scope = 'openid profile write:demo';
const logoutRedirectTo = 'https://www.hourone.ai';
//
const signUrl = 'https://api.hourone.ai/sign';
//
const cdnBaseDomain = 'https://d2k7tfe5jyh86k.cloudfront.net/';

const Config = {
	auth0: {
		domain,
		clientId,
		callbackUrl,
		apiUrl,
		responseType,
		scope,
		logoutRedirectTo
	},
	signUrl,
	cdnBaseDomain
};

export default Config;
