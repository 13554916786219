import React from "react";
import styled from "@emotion/styled";
import * as PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import ProspectsActions from "../../store/prospect/ProspectActions";

import Colors from "../../assets/Colors";
import { ArrowDown, HourOne, Profile } from "../../assets/Images";
import "bootstrap/dist/css/bootstrap.min.css";
import "./prompt.css";
import queryString from "query-string";

class Prompt extends React.Component {
    constructor(props) {
        super(props);
        const text = decodeURI(queryString.parse(this.props.location.search)["text"]);
        const prospect = queryString.parse(this.props.location.search)["prospect"];
        const project = queryString.parse(this.props.location.search)["project"];
        const batch = queryString.parse(this.props.location.search)["batch"];
        const index = queryString.parse(this.props.location.search)["index"];
        //encodeURI(uri);

        this.state = {
            elem: document.documentElement,
            speed: 23,
            size: 5,
            showPromot: false,
            containerVh: 100,
            text: text != "undefined" ? text : "",
            freq: 250,
            translate: 0,
            step: 1,
            isPause: false,
            isMirror: true,
            isLtr: true,
            prospect: { name: prospect != "undefined" ? prospect : undefined },
            project: { name: project != "undefined" ? project : undefined },
            batch: { name: batch != "undefined" ? batch : undefined },
            index: index != "undefined" ? Number(index) : undefined,
        };
        // if (query['text']) {
        // 	var decodedUrl = decodeURIComponent(query['text']);
        // 	$('#contentInput').val(decodedUrl);
        // }
    }
    componentDidMount() {
        if (this.state.prospect && this.state.project && this.state.batch && this.state.index) {
            console.log("getting textes");
            this.props.getTexts(this.state.prospect, this.state.project, this.state.batch);
        }
    }
    openFullscreen() {
        if (this.state.elem.requestFullscreen) {
            this.state.elem.requestFullscreen();
        } else if (this.state.elem.mozRequestFullScreen) {
            /* Firefox */
            this.state.elem.mozRequestFullScreen();
        } else if (this.state.elem.webkitRequestFullscreen) {
            /* Chrome, Safari and Opera */
            this.state.elem.webkitRequestFullscreen();
        } else if (this.state.elem.msRequestFullscreen) {
            /* IE/Edge */
            this.state.elem.msRequestFullscreen();
        }
    }
    /* Close fullscreen */
    closeFullscreen() {
        if (document.exitFullscreen) {
            document.exitFullscreen();
        } else if (document.mozCancelFullScreen) {
            /* Firefox */
            document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
            /* Chrome, Safari and Opera */
            document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) {
            /* IE/Edge */
            document.msExitFullscreen();
        }
    }
    onSpeedChange = (e) => {
        this.setState({
            speed: e.target.value,
        });
    };

    onSizeChange = (e) => {
        this.setState({
            size: e.target.value,
        });
    };

    onPromptClick = () => {
        this.openFullscreen();
        console.log(this.contentEl.getClientRects()[0].height)
        this.setState(
            {
                showPromot: true,
                containerVh: 0,
                height: this.contentEl.getClientRects()[0].height,
            },
            () => {
                this.estimateDuration();
            }
        );
    };

    onHideClick = () => {
        this.killTimer();
        this.closeFullscreen();
        this.setState({
            showPromot: false,
            containerVh: 100,
        });
    };

    onTextChange = (text) => {
        this.setState({
            text: text,
        });
    };

    onSizeUpClick = () => {
        this.setState({
            size: Number(this.state.size) + 1,
        });
    };
    onSizeDownClick = () => {
        this.setState({
            size: Number(this.state.size) - 1,
        });
    };
    onSpeedUpClick = () => {
        this.setState({
            speed: Number(this.state.speed) + 1,
        });
    };
    onSpeedDownClick = () => {
        this.setState({
            speed: Number(this.state.speed) - 1,
        });
    };
    killTimer = () => {
        clearTimeout(this.state.timer);
        this.state.timer = null;
    };
    onPlayClick = () => {
        this.killTimer();
        var n = 1;
        // const height = 500;
        if (this.state.isPause) {
            n = -(this.state.translate / this.state.step);
            this.setState({
                isPause: false,
                isPlaying: true
            });
        }
        const context = this;
        function upLoop() {
            var start;

            context.setState({
                translate: -n * context.state.step,
                interval: Math.round(context.state.freq / context.state.speed),
            });

            if (context.state.translate > -context.state.height) {
                n = n + 1;
                context.state.timer = setTimeout(upLoop, context.state.interval);
            } else {
                console.log("end")
            }
        }
        const interval = Math.round(context.state.freq / context.state.speed);
        this.setState({
            interval: interval,
            isPause: false,
            isPlaying: true,
        });
        this.state.timer = setTimeout(upLoop, interval);
    };

    onPauseClick = () => {
        this.killTimer();
        this.setState({
            isPlaying: false,
            isPause: true,
        });
    };
    onStopClick = () => {
        this.killTimer();
        console.log(this.contentEl.getClientRects()[0].height)
        this.setState({
            isPlaying: false,
            isPause: true,
            translate: 0,
            interval: 100,
            containerVh: 0,
            height: this.contentEl.getClientRects()[0].height,
        },
            () => {
                this.estimateDuration();
            });
    };
    onMirrorClick = () => {
        this.setState({
            isMirror: !this.state.isMirror,
        });
    };
    onDirectionClick = () => {
        this.setState({
            isLtr: !this.state.isLtr,
        });
    };

    estimateDuration = () => {
        const interval = Math.round(this.state.freq / this.state.speed);
        const duration = this.state.height / (this.state.step / interval) / 1000;
        var date = new Date(null);
        date.setSeconds(Math.round(duration));
        var result = date.toISOString().substr(11, 8);
        this.setState({
            duration: result,
        });
    };
    next = () => {
        this.setState(
            {
                index: this.state.index + 1,
            },
            () => {
                this.onStopClick();
            }
        );
    };
    prev = () => {
        this.setState(
            {
                index: this.state.index - 1,
            },
            () => {
                this.onStopClick();
            }
        );
    };

    render() {
        const { intl, history } = this.props;

        const pathname = history.location.pathname;
        const promptContainerStyle = {
            transform: `translate(0, ${this.state.containerVh}vh)`,
        };
        const prompterContentStyle = {
            // transitionDuration: Math.round(this.state.interval) * 10 + 'ms',
            transform: `translateY( ${Math.round(this.state.translate)}px ) scale(${this.state.isMirror ? -1 : 1},1)`,
            fontWeight: "bold",
            direction: this.state.isLtr ? "ltr" : "rtl",
            textAlign: this.state.isLtr ? "left" : "right",
            fontSize: this.state.size + "rem",
        };
        // console.log("tr" + prompterContentStyle.transform);
        const promptContainerShowStyle = {
            display: this.state.showPromot ? "block" : "none",
        };
        const promptTextStyle = {};

        const { speed } = this.props;
        // console.log(this.state);
        return (
            <div>
                <div className="container">
                    <div className="main">
                        <textarea
                            className="form-control"
                            id="contentInput"
                            autoFocus
                            placeholder="Write or Paste text to prompt..."
                            value={
                                this.state.prospect &&
                                    this.state.project &&
                                    this.state.batch &&
                                    this.props.texts &&
                                    this.props.texts.length > 0
                                    ? this.props.texts[this.state.index].text
                                    : this.state.text
                            }
                            onChange={(e) => this.onTextChange(e.target.value)}
                        />
                        <div className="form-group text-center my-5">
                            {this.props.texts && this.props.texts.length > 0 && this.state.index > 0 ? (
                                <button onClick={this.prev} type="button" className="btn">
                                    Previous
                                </button>
                            ) : (
                                ""
                            )}
                            <button
                                onClick={this.onPromptClick}
                                type="button"
                                id="promptBtn"
                                className="btn btn-lg btn-tdark pl-5 pr-5"
                            >
                                <i className="fas fa-tv-retro" />
                                Prompt
                            </button>
                            {this.props.texts &&
                                this.props.texts.length > 0 &&
                                this.state.index + 1 < this.props.texts.length ? (
                                <button onClick={this.next} type="button" className="btn">
                                    Next
                                </button>
                            ) : (
                                ""
                            )}
                        </div>
                    </div>
                </div>

                <div id="prompterContainer" style={promptContainerStyle}>
                    <div
                        id="prompterContent"
                        className="container"
                        style={prompterContentStyle}
                        ref={(el) => {
                            // el can be null - see https://reactjs.org/docs/refs-and-the-dom.html#caveats-with-callback-refs
                            if (!el) return;
                            this.contentEl = el;
                        }}
                    >
                        {this.state.prospect &&
                            this.state.project &&
                            this.state.batch &&
                            this.props.texts &&
                            this.props.texts.length > 0
                            ? this.props.texts[this.state.index].text
                            : this.state.text}
                    </div>
                </div>

                <div id="prompterZone" style={promptContainerShowStyle} />

                <div
                    id="prompterControls"
                    className={this.state.isMirror ? "mirror" : ""}
                    style={promptContainerShowStyle}
                >
                    <div className="form-inline column">
                        <div className="form-group mb-2 mr-2">
                            {this.state.isPlaying ? (
                                <button id="pausePrompt" className="btn btn-warning" onClick={this.onPauseClick}>
                                    <i className="fas fa-pause" />
                                </button>
                            ) : (
                                <button id="playPrompt" className="btn btn-tdark" onClick={this.onPlayClick}>
                                    <i className="fas fa-play" />
                                </button>
                            )}
                        </div>
                        <div className="form-group mb-2 mr-2">
                            <button id="stopPrompt" className="btn btn-danger" onClick={this.onStopClick}>
                                <i className="fas fa-stop" />
                            </button>
                        </div>
                        <div className="form-group mb-2 mr-2">
                            {this.props.texts && this.props.texts.length > 0 && this.state.index > 0 ? (
                                <button onClick={this.prev} type="button" className="btn btn-primary">
                                    Previous
                                </button>
                            ) : (
                                ""
                            )}
                        </div>{" "}
                        <div className="form-group mb-2 mr-2">
                            {this.props.texts &&
                                this.props.texts.length > 0 &&
                                this.state.index + 1 < this.props.texts.length ? (
                                <button onClick={this.next} type="button" className="btn btn-primary">
                                    Next
                                </button>
                            ) : (
                                ""
                            )}
                        </div>
                    </div>
                </div>
                <div
                    id="prompterControls-2"
                    className={this.state.isMirror ? "mirror" : ""}
                    style={promptContainerShowStyle}
                >
                    <div className="form-inline">
                        <div className="form-group mb-2 mr-2">
                            <button onClick={this.onHideClick} id="hideBtn" className="btn bg-white text-dark">
                                <i className="fal fa-times" />
                            </button>
                        </div>
                        <div className="form-group mb-2 mr-2">
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="speed-addon">
                                        <i className="far fa-tachometer" />
                                    </span>
                                </div>
                                <input
                                    onChange={this.onSpeedChange}
                                    value={this.state.speed}
                                    type="number"
                                    id="speed"
                                    step="1"
                                    min="1"
                                    max="50"
                                    className="form-control number-input change-settings"
                                    placeholder="10"
                                    aria-label="speed"
                                    aria-describedby="speed-addon"
                                />
                                <div className="input-group-append" onClick={this.onSpeedUpClick} id="speed-up">
                                    <span className="input-group-text">
                                        <i className="fas fa-chevron-up" />
                                    </span>
                                </div>
                                <div className="input-group-append" onClick={this.onSpeedDownClick} id="speed-down">
                                    <span className="input-group-text">
                                        <i className="fas fa-chevron-down" />
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="form-group mb-2 mr-2">
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="size-addon">
                                        <i className="far fa-text-height" />
                                    </span>
                                </div>
                                <input
                                    type="number"
                                    onChange={this.onSizeChange}
                                    value={this.state.size}
                                    id="size"
                                    step="1"
                                    min="1"
                                    max="20"
                                    className="form-control number-input change-settings"
                                    placeholder="10"
                                    aria-label="size"
                                    aria-describedby="size-addon"
                                />
                                <div className="input-group-append" onClick={this.onSizeUpClick} id="size-up">
                                    <span className="input-group-text">
                                        <i className="fas fa-chevron-up" />
                                    </span>
                                </div>
                                <div className="input-group-append" onClick={this.onSizeDownClick} id="size-down">
                                    <span className="input-group-text">
                                        <i className="fas fa-chevron-down" />
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="form-group mb-2 mr-2">
                            <button id="mirror" className="btn btn-primary" onClick={this.onMirrorClick}>
                                Mirror
                            </button>
                        </div>
                        <div className="form-group mb-2 mr-2">
                            <button id="mirror" className="btn btn-primary" onClick={this.onDirectionClick}>
                                ltr &lt;&gt; rtl
                            </button>
                        </div>
                        <div className="form-group mb-2 mr-2 text-white">
                            <span id="elapsedContainer" className="pl-2 pr-2">
                                00:00:00
                            </span>
                            /
                            <span id="durationContainer" className="pl-2 pr-2">
                                {this.state.duration}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

Prompt.propTypes = {
    // toggleMyProfile: PropTypes.func.isRequired,
    // clearCurrentProject: PropTypes.func.isRequired
    getTexts: PropTypes.func.isRequired,
};

Prompt.defaultProps = {
    speed: 19,
    size: 10,
    showPromot: false,
    containerVh: 100,
    text: "",
    texts: [],
};

const mapStateToProps = (state) => {
    // console.log(state);
    return {
        texts: state.prospect.texts,
        batch: state.prospect.batch,
        project: state.prospect.project,
        prospect: state.prospect,
    };
};

const mapDispatchToProps = {
    getTexts: ProspectsActions.getTexts,
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(withRouter(Prompt)));

const Container = styled.div`
    background-color: ${Colors.White};
    padding: 10px 18px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 2px 8px 0 rgba(237, 237, 237, 0.5);
`;
