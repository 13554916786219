import React from 'react';
import { IntlProvider, addLocaleData } from 'react-intl';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import locales from './app/locales/index';
import Main from './app/Main';
import redux from './app/store/store';
import { BrowserRouter as Router } from 'react-router-dom';

// Localization
for (const key in locales) {
	addLocaleData({
		locale: key,
		pluralRuleFunction: () => {}
	});
}

function App() {
	return (
		<IntlProvider locale="en-US" key="en-US" messages={locales['en-US']}>
			<Provider store={redux.store}>
				<PersistGate loading={null} persistor={redux.persistor}>
					<Router>
						<Main />
					</Router>
				</PersistGate>
			</Provider>
		</IntlProvider>
	);
}

export default App;
