import React from 'react';
import { injectIntl } from 'react-intl';

import loader from './images/loading.svg';
import hourOne from './images/hourone.svg';
// import profile from './images/profile.svg';
// import arrowDown from './images/arrow-down.svg';
// import viPoint from './images/vi-point.svg';
// import solutionsOne from './images/solutions-1.png';
// import solutionsTwo from './images/solutions-2.png';
// import solutionsThree from './images/solutions-3.png';
// import solutionsFour from './images/solutions-4.png';
// import brief from './images/brief.svg';
// import character from './images/character.svg';
// import transcripts from './images/transcripts.svg';
// import videoCreation from './images/video-creation.svg';
// import arrowRight from './images/arrow-right.svg';

// Basic object
class ImageComp extends React.Component {
	render() {
		const { src, alt, intl } = this.props;

		let altString = '';
		if (alt) {
			altString = intl.formatMessage({ id: alt });
		}

		return <img src={src} alt={altString} {...this.props} />;
	}
}

const Image = injectIntl(ImageComp);

export const Loader = (props) => <Image src={loader} alt={'Loading'} {...props} />;

export const HourOne = (props) => <Image src={hourOne} alt={'HourOne'} {...props} />;

// export const Profile = (props) => <Image src={profile} alt={'Profile'} {...props} />;

// export const ArrowDown = (props) => <Image src={arrowDown} alt={'Profile'} {...props} />;

// export const ViPoint = (props) => <Image src={viPoint} {...props} />;

// export const SolutionsOne = (props) => <Image src={solutionsOne} alt={'ECommerce'} {...props} />;

// export const SolutionsTwo = (props) => <Image src={solutionsTwo} alt={'ProductionMadeEasy'} {...props} />;

// export const SolutionsThree = (props) => <Image src={solutionsThree} alt={'MassParametricVideo'} {...props} />;

// export const SolutionsFour = (props) => <Image src={solutionsFour} alt={'Enterprise'} {...props} />;

// export const Brief = (props) => <Image src={brief} alt={'Brief'} {...props} />;

// export const Character = (props) => <Image src={character} alt={'CharacterBundle'} {...props} />;

// export const Transcripts = (props) => <Image src={transcripts} alt={'TranscriptsSource'} {...props} />;

// export const VideoCreation = (props) => <Image src={videoCreation} alt={'VideoCreation'} {...props} />;

// export const ArrowRight = (props) => <Image src={arrowRight} alt={''} {...props} />;

// export const HrLine = (props) => <Image src={hrLine} alt={''} {...props} />;

// export const PlayVideo = (props) => <Image src={playVideo} alt={''} {...props} />;

// export const PagerArrowUp = (props) => <Image src={pagerArrowUp} alt={''} {...props} />;

// export const PagerArrowDown = (props) => <Image src={pagerArrowDown} alt={''} {...props} />;

// export const Calendar = (props) => <Image src={calendar} alt={'SelectDates'} {...props} />;

// export const GrayArrowDown = (props) => <Image src={grayArrowDown} alt={'SelectDates'} {...props} />;

// export const Avatar = (props) => <Image src={myProfile} alt={'MyProfile'} {...props} />;

// export const ProjectDone = (props) => <Image src={projectDone} alt={''} {...props} />;

// export const ProjectWaiting = (props) => <Image src={projectWaiting} alt={''} {...props} />;

// export const ProjectCreation = (props) => <Image src={projectCreation} alt={''} {...props} />;

// export const AddCircle = (props) => <Image src={addCircle} alt={''} {...props} />;

// export const Dropdown = (props) => <Image src={dropdown} alt={''} {...props} />;

// export const Address = (props) => <Image src={address} alt={''} {...props} />;

// export const Email = (props) => <Image src={email} alt={''} {...props} />;

// export const PhoneNumber = (props) => <Image src={phoneNumber} alt={''} {...props} />;

// export const OrangeAvatar = (props) => <Image src={orangeAvatar} alt={''} {...props} />;

// export const NotificationWarning = (props) => <Image src={notificationWarning} alt={''} {...props} />;

// export const NotificationSuccess = (props) => <Image src={notificationSuccess} alt={''} {...props} />;

// export const GreyX = (props) => <Image src={greyX} alt={''} {...props} />;
