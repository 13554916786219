import React from "react";
import { Route, Switch } from "react-router-dom";
import Callback from "./auth/Callback";
import ErrorPage from "./components/authentication/ErrorPage";
import EmailVerification from "./components/authentication/EmailVerification";
import AuthenticatedRouter from "./router/AuthenticatedRouter";
import Mobile from "./components/mobile/Mobile";
import withTracker from "./analytics/withTracker";

class Main extends React.Component {
    componentDidMount() {
        // if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        //   this.props.history.push('/mobile');
        // }
    }

    render() {
        return (
            <Switch>
                <Route exact path="/callback" component={Callback} />
                <Route exact path="/verification" component={EmailVerification} />
                <Route exact path="/mobile" component={Mobile} />
                <Route exact path="/404" component={ErrorPage} />
                <Route path="/" component={AuthenticatedRouter} />
            </Switch>
        );
    }
}

export default withTracker(Main);
