import React from "react";
import styled from "@emotion/styled";
import { Route, Switch } from "react-router";
import TopBar from "../components/topbar/TopBar";
import ScrollToTop from "./ScrollToTop";
import Batches from "../components/batches/Batches";
import Projects from "../components/projects/Projects";
import Prospects from "../components/prospects/Prospects";
import Texts from "../components/texts/Texts";
import Prompt from "../components/prompt/Prompt";
import Home from "../components/home/Home";
import Footer from "../components/footer/Footer";
import auth from "../auth/Auth";
import FullScreenLoader from "../components/loader/FullScreenLoader";

class AuthenticatedRouter extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loaderVisible: true,
        };
    }

    componentDidMount() {
        auth.renewSession(() => {
            this.setState({
                loaderVisible: false,
            });
        }, window.location.href);
    }

    render() {
        return this.state.loaderVisible === true ? (
            <FullScreenLoader visible={this.state.loaderVisible} />
        ) : (
            <Container>
                <TopBar />
                <ScrollToTop>
                    <Content id="main-content-container">
                        <FixedWidth>
                            <Switch>
                                <Route path="/prospect/:prospect/:project/:batch" component={Texts} />
                                <Route path="/prospect/:prospect/:project/" component={Batches} />
                                <Route path="/prospect/:prospect/" component={Projects} />
                                <Route path="/prospects" component={Prospects} />
                                <Route path="/prompt" component={Prompt} />
                                <Route path="/" component={Prospects} />
                            </Switch>
                            <Footer />
                        </FixedWidth>
                    </Content>
                </ScrollToTop>
            </Container>
        );
    }
}

export default AuthenticatedRouter;

const Container = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
`;

const Content = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
`;

const FixedWidth = styled.div`
    width: 90%;
    display: flex;
    flex-direction: column;
    position: relative;
    flex: 1;
`;
