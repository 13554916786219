import React from 'react';
import styled from '@emotion/styled';
import { injectIntl } from 'react-intl';
import * as PropTypes from 'prop-types';
import Colors from '../../assets/Colors';
import { HourOne } from '../../assets/Images';
import auth from '../../auth/Auth';

class EmailVerification extends React.Component {
	onClickSignIn = () => {
		auth.login();
	};

	render() {
		const { success, intl } = this.props;

		let title = intl.formatMessage({ id: 'EmailVerificationTitleSuccess' });
		if (success !== true) {
			title = intl.formatMessage({ id: 'EmailVerificationTitleError' });
		}

		return (
			<Container>
				<HourOne width={46} height={41} />
				<Title>{title}</Title>
				{success !== true && <Text>{intl.formatMessage({ id: 'EmailVerificationSubtitleError' })}</Text>}
				<Button onClick={this.onClickSignIn}>{intl.formatMessage({ id: 'SignIn' })}</Button>
			</Container>
		);
	}
}

EmailVerification.propTypes = {
	success: PropTypes.bool
};

EmailVerification.defaultProps = {
	success: true
};

export default injectIntl(EmailVerification);

const Container = styled.div`
	height: 100%;
	width: 100%;
	background-color: ${Colors.White};
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;

const Title = styled.div`
	font-size: 20px;
	font-weight: 900;
	font-style: italic;
	text-align: center;
	color: ${Colors.TextDefault};
	margin-top: 36px;
`;

const Text = styled.div`
	font-size: 11px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.48;
	letter-spacing: normal;
	text-align: center;
	color: ${Colors.TextDefault};
	margin-top: 20px;
`;

const Button = styled.button`
	width: 193px;
	height: 29px;
	border-radius: 3px;
	box-shadow: 0 8px 8px -5px rgba(0, 0, 0, 0.24);
	background-color: ${Colors.Orange};
	background-image: linear-gradient(-180deg, ${Colors.LightOrange}, ${Colors.Orange} 90%);
	font-size: 10px;
	line-height: 2.1;
	text-align: center;
	color: ${Colors.White};
	margin-top: 46px;
	text-transform: uppercase;
	cursor: pointer;

	&:hover {
		opacity: 95;
	}
`;
