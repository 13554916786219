import React from 'react';
import styled from '@emotion/styled';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';
import Colors from '../../assets/Colors';
import { HourOne } from '../../assets/Images';

class Footer extends React.Component {
	onClickOption = (type) => {
		const { history } = this.props;
		history.push(`/${type}`);
	};

	renderRightsReserved = () => {
		const { intl } = this.props;

		const now = new Date();
		const year = now.getFullYear();

		return (
			<AllRightsContainer>
				<YearAndName>{`\u00A9 ${year} ${intl.formatMessage({ id: 'HourOne' })}`}</YearAndName>
				{/* <Divider />
				<AllRightsText>{intl.formatMessage({ id: 'AllRights' })}</AllRightsText> */}
				<Option onClick={() => this.onClickOption('terms')}>{intl.formatMessage({ id: 'Terms' })}</Option>
				<Option onClick={() => this.onClickOption('privacy')}>
					{intl.formatMessage({ id: 'PrivacyPolicy' })}
				</Option>
			</AllRightsContainer>
		);
	};

	render() {
		const { intl, history } = this.props;

		return <div />;
	}
}

export default injectIntl(withRouter(Footer));

const Container = styled.div`
	background-color: ${Colors.White};
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 22px 26px;
	border-top: 1px solid ${Colors.LightGray};
	flex-shrink: 0;
	justify-content: space-between;
`;

const LeftSide = styled.div`
	display: flex;
	flex-wrap: wrap;
	width: 300px;
`;

const Logo = styled.div`
	display: block;
	width: 300px;
	text-align: center;
`;

const RightSide = styled.div`
	display: flex;
	flex-wrap: wrap;
	flex-flow: row-reverse;
	width: 300px;
`;

const Option = styled.div`
	font-size: 10px;
	color: ${Colors.TextDefault};
	cursor: pointer;
	margin-left: 20px;

	&:hover {
		opacity: 0.6;
	}
`;

const AllRightsContainer = styled.div`
	display: flex;
	flex-direction: row;
	font-size: 12px;
	color: ${Colors.TextDefault};
	align-items: center;
`;

const YearAndName = styled.div``;
