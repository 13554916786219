const enUs = {
  Loading: 'Loading...',
  HourOne: 'Hour One. AI',
  Solutions: 'Solutions',
  StockCharacters: 'Stock Characters',
  MyCharacters: 'My own characters',
  Feedback: 'Feedback',
  MakeAVideo: 'Make a Video',
  Profile: 'Your Profile',
  AboutUs: 'About',
  Terms: 'Terms',
  PrivacyPolicy: 'Privacy',
  Faq: 'FAQ',
  Contact: 'Contact',
  AllRights: 'All rights reserved',
  WelcomeTo: 'Welcome to',
  HourOneTitle: 'Hour One.',
  HourOneSubtitle:
    'We are the world’s first artificial intelligence powered video production company. The platform enables you to create and use real-life and fictional characters in a simple and an easy way.',
  QuickProcess: 'Quick process',
  EasyOnBoarding: 'Easy on boarding',
  HighQuality: 'High quality',
  LargeCharactersRepo: 'Large characters repo',
  Copyright: 'Copyright',
  SolutionsSubtitle:
    'Giving companies the ability to create high quality video \nat a fraction of the cost and at hitherto unseen delivery times from brief submission to final video.\n' +
    'NO camera,  NO studio,  NO director.',
  FictionalPersona: 'Fictional Persona',
  FictionalPersonaSubtitle:
    'A fictional persona for high awareness marketing campaigns over any digital and social platforms',
  ProductionMadeEasy: 'Production Made Easy',
  ProductionMadeEasySubtitle: 'Addressable, Branding, RTM, News',
  MassParametricVideo: 'Mass Parametric Video',
  MassParametricVideoSubtitle: 'Individual marketing, Billing, Transactional',

  ECommerce: 'E-Commerce',
  ECommerceSubtitle: 'Product sale, Customer acquisition, In-shop, Catalogs',
  Enterprise: 'Enterprise',
  EnterpriseSubtitle: 'Guides, Reports, Intra-company',
  //
  ProjectNameTitle: 'Make Me a Video - quick and simple process',
  ProjectNameSubtitle:
    'In this simple process we will guide you through our way to easily create videos at scale. Below you can see a short overview of the process explaining the stages you are about to go through.',
  Brief: 'Brief',
  TranscriptsSource: 'Transcript',
  CharacterBundle: 'Characters',
  VideoCreation: 'Submit',
  BriefSubtitle:
    'For the brief stage, please provide key requirements for your video project, including the feel and atmosphere',
  TranscriptsSourceSubtitle:
    'At the transcript stage please enter the exact words that you want your character to say, and describe the tone. There is also an opportunity here to add a video fo you or someone else saying it',
  CharacterBundleSubtitle:
    'Here, you will choose the character or bundle of characters to convey your message and deliver your content',
  VideoCreationSubtitle:
    'At the video creation stage you will review all the details you’ve entered and submit your project to production',
  ProjectNamePlaceholder: 'Name your project',
  Start: 'Start',
  Back: 'Back',
  //
  BriefExplanation:
    'Please provide key requirements for your video project, including the feel and atmosphere. This is a great place to provide any additional context for your project. The more we understand your ideas the better the product we can deliver.',
  BriefLimitation: '*Brief should be at least 4 sentences and no more than 400 words',
  YourBrief: 'Your Brief',
  BriefExample: 'Brief Example',
  BriefExampleText:
    'A promo for Sunday football game with one of the players.\n' +
    'The promo should be funny.\n' +
    'It should be 15-20 sec in length.\n' +
    'To be published on the sports channel Facebook.',
  BriefPlaceholder: 'Write a brief for your video',
  Continue: 'Continue',
  //
  Transcript: 'Transcript',
  TranscriptExplanation:
    'Enter your desired transcript. Use punctuation to illustrate the rhythm of your text. You can use separate rows to indicate a longer pause between sentences.',
  TranscriptLimitation: '*This should be at least two sentences, and no more than 300 words',
  TranscriptPlaceholder: 'Write a transcript',
  TranscriptVariation: 'Transcript Variation',
  TranscriptExample: 'Transcript Example',
  TranscriptExampleText:
    '“Hello and welcome to Hour One.\n' +
    '\n' +
    'The video that you are about to see was made using artificial intelligence technologies.\n' +
    '\n' +
    'The characters in this video are “synthetic,” which means they were computer-generated using artificial intelligence technologies.”\n',
  Speak: 'Speak',
  //
  SourceCapture: 'Source Capture',
  SourceCaptureExplanation:
    'Source video capture is a great way to ensure your video is delivered in your desired tone. It enables us to transfer all the subtle gestures, rhythm and performance to your final video. We will otherwise generate a voice character for you, based on your brief',
  SourceCaptureLimitation: '*note that this is not required but highly recommended',
  Play: 'Play',
  SourceCaptureInstructionsTitle: 'How to capture a source video',
  SourceCaptureInstructionsText:
    'Watch the video beginning to end with close attention to all the details. Especially to the following highlights',
  Highlights: 'Highlights:',
  HavingTrouble: 'Having Trouble',
  UploadingSource: 'Uploading source',
  UploadingSourceText:
    'We currently use google drive links to download source videos. Upload your source video to your Google Drive and share the link with us. Our system will be able to access it from there',
  GoogleDriveLink: 'Google Drive Link',
  ContinueWithout: 'Continue Without',
  //
  CharacterBundleExplanation:
    'Characters are the heart of hour one. Browse through our character database and choose characters that fit your needs. You can either select a single character or specify a character bundle. A character bundle means you will create multiple video executions with your content, via different characters',
  FeaturedCharacters: 'Featured Characters',
  FeaturedBundles: 'Featured Bundles',
  YourSelection: 'Your Selection',
  //
  VideoCreationExplanation:
    'That’s all it takes to make videos today.\n' +
    'When submitting your request, our platform will start producing the videos.',
  ReviewYourSubmissionTitle: 'Review Your Submission',
  ReviewYourSubmissionText:
    'Go over your submission details. Put close attention to each section to make sure we will deliver the best video for you. You can change any detail you’ve enter.',
  ReviewProjectName: 'Project Name',
  Watch: 'Watch',
  CharactersAndBundles: 'Characters and bundles',
  Change: 'Change',
  ContactInfo: 'Contact Info',
  ContactInfoSubtitle:
    'We would like to know a little bit more about you. Providing just a few details about yourself can help us give you the best experience',
  SubmitRequest: 'Submit Request',
  ContactFullName: 'Full Name',
  ContactFullNamePlaceholder: 'John Doe',
  ContactTitle: 'Title',
  ContactTitlePlaceholder: 'Innovation Lead',
  AdditionalAssets: 'Additional Assets',
  //
  SetupCallTitle: 'We would like to setup a call if possible',
  SetupCallSubtitle:
    'We are working hard to keep our customers delighted. We would like to setup a call to go over your video request and make sure we did not miss any important detail',
  Skip: 'Skip',
  SetupCall: 'Setup Call',
  PhoneNumber: 'Phone Number',
  PhoneNumberPlaceholder: '+1555857465',
  PhoneNumberDetails:
    'Phone number\n' +
    '\n' +
    'We will not make any calls that are not directly related to your request. Ideally we would only call you once and the call should not last more than 15 minutes\n',
  CallDateDetails:
    'Call date\n' +
    '\n' +
    'If you can provide a date range it will help us try to reach you when it is most comfortable with your schedule',
  CallTimeDetails:
    'Call time\n' +
    '\n' +
    'We will only call you in between the times you will set here. This helps us reach you at your convenience',
  BestTimeToCall: 'Best time for a call',
  CallTime: 'Call time',
  SelectDates: 'Select Dates',
  StartDate: 'Start Date',
  EndDate: 'End Date',
  SelectTimePlaceholder: '20:00 GMT',
  //
  FinalApproval: 'Final Approval',
  FinalApprovalDetails: 'Your video request is now ready to be sent to our platform.',
  CreateVideo: 'Create Video',
  //
  ThankYou: 'Thank you for your submission',
  ReviewYourProject: 'If you would like, you can review you project status in {personal} section',
  WeAreReviewing: 'We are reviewing your request and might contact you for additional details',
  Congratulations: 'Congratulations',
  FinishVideoCreation:
    'That’s all it takes to produce videos these days.\n' +
    'You will be able to follow your project status in {personal} section',
  Personal: 'My videos',
  ReceiveEmailSoon: 'You will receive an email as soon as your video is ready.',
  //
  MyProfile: 'My Profile',
  Username: 'Hello! {username},\n' + 'Welcome to Hourone.',
  MyVideos: 'My Videos',
  SignOut: 'Sign Out',
  //
  ProjectName: 'Project Name',
  Status: 'Status',
  Edit: 'Edit',
  Review: 'Review',
  View: 'View (disabled)',
  //
  ShareWithCoWorkers: 'Share with your co-workers',
  ShareSubtitle:
    'Invite you co-workers to enjoy what you have created without leaving your seat. Make sure you and them are in the same email domain (niki@notgmail.com)',
  SharePlaceholder: 'jardson.araujo@gmail.com',
  LeaveUsFeedback: 'Leave Us Feedback',
  LeaveUsFeedbackSubtitle:
    'We are truly thankful for your interest in what we do\n' +
    'We are also very interested in your experience with us\n' +
    'It would make us even better with a bit of feedback',
  WriteYourFeedback: 'Write your feedback',
  InviteAndSubmitFeedback: 'Invite & Submit feedback',
  //
  Support: 'Support',
  SupportText: 'We are delighted to assist with anything you need.',
  WhatOnYourMind: 'What is on your mind?',
  WriteAnything: 'Write anything',
  PhoneNumberRequired: 'Phone number *',
  GetBackToMe: 'Get Back To Me',
  //
  FaqTitle: 'Frequently asked questions',
  //
  TermsOfUse: 'Terms Of Use',
  //
  ContactUs: 'Contact Us',
  DetailsTitle: "We'd love to hear from you",
  DetailsSubtitle: 'We are delighted to assist with anything you need.',
  CompanyAddress: '385 Noah Place Suite 878',
  CompanyPhoneNumber: '877-255-7945',
  CompanyEmail: 'niki@hourone.ai',
  FullName: 'Full Name',
  ContactUsPlaceholder: 'Write something...',
  //
  StockCharactersSubtitle:
    ' A wide and interesting variety of characters is enables us to serve your specific needs.\nWe are constantly adding new characters and would love to hear if you can’t find a character that fits your needs.\nLet us know about it in the feedback section',
  MoreComingSoon: 'More Coming soon',

  //
  MyOwnCharacters: 'My Own Characters',
  MyOwnCharactersSubtitle:
    'We enable our users to create their own character. Process is simple and requires a short video capture of the target character. We will guide you how to maximise the capture for getting the best quality in the simplest process. Submit and the platform will do the rest.',
  ComingSoon: 'Coming soon',
  //
  FeedbackTitle: 'Please leave us feedback',
  FeedbackSubtitle:
    'We are truly thankful for your interest in what we do. We are also very interested in your experience with us It would make us even better with a bit of feedback.Anything that comes to mind like suggesting characters, thoughts about the process, your specific needs and how your day is going\n',
  SuggestCharacters: 'Suggest characters',
  SubmitFeedback: 'Submit Feedback',
  //
  AboutUsSubtitle:
    'Hour One enable its customers to create synthetic video of real-life and fictional characters in voice-photo real quality. The platform is based on a new paradigm where the production is defined separately from the content.',
  Platform: 'Platform',
  Process: 'Process',
  AICore: 'AI Core',
  PlatformSubtitle:
    'Our computer generated video technology enables to apply any content over the pre-captured video and audio with no limitation of the type of content and length. The user can choose a character from a growing bank of characters or create a new character.',
  ProcessSubtitle:
    'Content input can be either brief and text or audio and video which are also referred to as the source content.  Output format range from head shot to mid-shot.',
  AICoreSubtitle:
    'A state of the art Artificial Intelligence based algorithms are the core technology running in the background. We do not recommend trying this at home.',
  //
  GoogleDrivePlaceholder: 'https://docs.google.com/prese',
  //
  EmailVerificationTitleSuccess: 'Verification Success',
  EmailVerificationTitleError: 'Verification Error',
  EmailVerificationSubtitleError: 'Sorry for that. Please contact niki@hourone.ai',
  SignIn: 'Sign In',
  //
  ProjectNameIsMandatory: 'Please fill the project name',
  //
  Loading0: 'Loading',
  Loading1: 'Loading.',
  Loading2: 'Loading..',
  Loading3: 'Loading...',
  //
  SomethingWentWrong: 'Something went wrong. Please contact niki@hourone.ai.',
  BriefLengthError: 'Brief should be at least 20 words and not more than 400 words',
  TranscriptLengthError: 'Brief should be at least 10 words and not more than 300 words',
  InvalidSourceError: 'Your Google Drive link is invalid',
  EmptyCharactersError: 'Please select at least 1 character / bundle',
  EmptyContactNameTitleError: 'Please fill both contact name and title',
  MissingDetailsPhoneCallError: 'Please fill all fields',
  EmailsNotValidError: 'One or more emails are not valid',
  FeedbackEmptyError: 'Please fill feedback',
  //
  Creation: 'Creation',
  PendingReview: 'Pending Review',
  PendingFinalApproval: 'Pending Final Approval',
  Started: 'Started',
  Completed: 'Completed',
  //
  VideoShareSuccess: 'Your video has been shared with your colleagues.',
  //
  FeedbackShareSuccess: 'Thank you for sharing your feedback.',
  //
  SupportEmptyError: 'Please fill all fields',
  ContactUsEmptyError: 'Please fill all fields',
  SupportSuccess: 'Thank you. We will get back to you soon.',
  ContactUsSuccess: 'Thank you. We will get back to you soon.',
  //
  Submit: 'Submit',
  //
  AgreeToTerms: 'I agree to the {terms_and_conditions}',
  TermsAndConditions: 'Terms and Conditions',
  PleaseAcceptTerms: 'Please accept the terms and conditions',
  //
  ErrorMessage: 'Something went wrong, if the problem pressists. contact us at ',
  Oops: 'Oops,',
  NotSameDomainError: 'All emails should be on the same domain as your email',
  //
  BlockMobile: 'This site is designed for desktop use only'
};

export default enUs;
