import React from 'react';
import ReactGA from 'react-ga';
import { withRouter } from 'react-router';

ReactGA.initialize('UA-140622501-2');

const withTracker = (WrappedComponent, options = {}) => {
  const trackPage = page => {
    ReactGA.set({
      page,
      ...options,
    });
    ReactGA.pageview(page);
  };

  // eslint-disable-next-line
  const HOC = class extends React.Component {
    componentDidMount() {
      // eslint-disable-next-line
      const page = this.props.history.location.pathname + this.props.history.location.search;
      trackPage(page);
    }

    componentDidUpdate(prevProps) {
      const currentPage =
        prevProps.location.pathname + prevProps.location.search;
      const nextPage =
        this.props.location.pathname + this.props.location.search;

      if (currentPage !== nextPage) {
        trackPage(nextPage);
      }
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  };

  return withRouter(HOC);
};

export default withTracker;
