import APIConstants from './APIConstants';
import auth from '../../auth/Auth';

const apiMiddleware = ({ dispatch }) => next => action => {
  if (action.type !== APIConstants.API_REQUEST) {
    return next(action);
  }

  const { url, meta, baseAction, payload } = action;

  const configs = {};

  configs.headers = meta.headers || {};
  const accessToken = auth.getAccessToken();
  configs.headers.Authorization = `Bearer ${accessToken}`;

  configs.method = meta.method;

  configs.headers.Accept = 'application/json';
  configs.headers['Content-Type'] = 'application/json';

  if (meta.body && meta.method !== 'GET') {
    configs.body = JSON.stringify(meta.body);
  }

  // trigger PENDING
  dispatch({
    type: baseAction.PENDING,
    payload,
  });

  const handleErrors = (response) => {
    if (!response.ok) {
      throw response;
    }
    return response.json();
  };

  let fetchUrl = `${APIConstants.BASE_URL}${url}`;

  return fetch(fetchUrl, configs)
    .then(handleErrors)
    .then((response) => {
      dispatch({
        type: baseAction.SUCCESS,
        payload: response,
        requestPayload: payload,
      });
    }).catch((error) => {
      console.warn('Network error:', error);

      if (error.json) {
        error.json()
          .then((jsonResponse) => {
            dispatch({
              type: baseAction.FAILURE,
              payload: jsonResponse,
              requestPayload: payload,
            });
          })
          .catch(() => {
            dispatch({
              type: baseAction.FAILURE,
              payload: { error },
              requestPayload: payload,
            });
          });
      } else {
        dispatch({
          type: baseAction.FAILURE,
          payload: { error },
          requestPayload: payload,
        });
      }
    });
};

export default apiMiddleware;
