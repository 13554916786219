const Colors = {
	Black: '#000000',
	White: '#FFFFFF',
	LightGray: '#F2F5F7',
	MidGray: '#EEEEEE',
	DarkGray: '#34495E',
	DarkBlue: '#013D68',
	Blue: '#014a7e',
	LightBlue: '#01538d',
	Orange: '#ff9627',
	LightOrange: '#ffa13d',
	Green: '#659f5c',
	DarkGreen: '#3f6910',
	Yellow: '#F8C51C',
	TextDefault: '#183249',
	Transparent: 'rgba(0,0,0,0)',
	Red: '#f2dede',
	MidRed: '#ebccd1',
	DarkRed: '#a94442'
};

export default Colors;
