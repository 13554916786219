import APIConstants from '../api/APIConstants';

const getPrefix = (action) => `ProspectActionTypes.${action}`;

const getProspects = APIConstants.createAPIRequestAction(getPrefix('getProspects'));

const getProjects = APIConstants.createAPIRequestAction(getPrefix('getProjects'));

const getBatches = APIConstants.createAPIRequestAction(getPrefix('getBatches'));
const getTexts = APIConstants.createAPIRequestAction(getPrefix('getTexts'));

const selectProspect = APIConstants.createAPIRequestAction(getPrefix('selectProspect'));

const selectProject = APIConstants.createAPIRequestAction(getPrefix('selectProject'));
const selectBatch = APIConstants.createAPIRequestAction(getPrefix('selectBatch'));

const ProspectActionTypes = {
	getProspects,
	getProjects,
	selectProspect,
	selectProject,
	getBatches,
	getTexts,
	selectBatch
};

export default ProspectActionTypes;
