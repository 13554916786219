import React from "react";
import styled from "@emotion/styled";
import * as PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import { withRouter } from "react-router";
import { connect } from "react-redux";

import ProspectsActions from "../../store/prospect/ProspectActions";

import Colors from "../../assets/Colors";
import { ArrowDown, HourOne, Profile } from "../../assets/Images";
import "bootstrap/dist/css/bootstrap.min.css";

class Texts extends React.Component {
    constructor(props) {
        super(props);
        // const { match: { params } } = this.props;
        // console.log(params);
        this.state = {
            elem: document.documentElement,
        };
    }
    u;
    componentDidMount() {
        this.props.getTexts(this.props.prospect, this.props.project, this.props.batch);
    }
    onTexttClick = (index) => {
        const texts = this.props.texts;
        this.props.history.push(
            `/prompt?prospect=${this.props.prospect.name}&project=${this.props.project.name}&batch=${this.props.batch.name}&index=${index}`
        );
        // window.text = texts[index].text
        // window.next = texts[index + 1].text);
        // this.props.history.push("/prompt");
    };
    render() {
        const { intl, history } = this.props;
        const {
            match: { params },
        } = this.props;

        const pathname = history.location.pathname;
        return (
            <div>
                <h1>Batch: {params.batch}</h1>
                {this.props.texts.map((e, i) => {
                    return (
                        <div key={i}>
                            <Container
                                className="clickable"
                                onClick={() => {
                                    this.onTexttClick(i);
                                }}
                            >
                                {e.short}
                            </Container>
                        </div>
                    );
                })}
            </div>
        );
    }
}

Texts.propTypes = {
    getTexts: PropTypes.func.isRequired,
};

Texts.defaultProps = {
    texts: [],
};

const mapStateToProps = (state) => {
    console.log(state);
    return {
        texts: state.prospect.texts,
        batch: state.prospect.batch,
        project: state.prospect.project,
        prospect: state.prospect,
    };
};

const mapDispatchToProps = {
    getTexts: ProspectsActions.getTexts,
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(withRouter(Texts)));

const Container = styled.div`
    background-color: ${Colors.White};
    padding: 10px 18px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 2px 8px 0 rgba(237, 237, 237, 0.5);
`;
