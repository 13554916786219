import { combineReducers, createStore, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import prospect from './prospect/ProspectReducer';

// Reducers

// Middlewares
import apiMiddleware from './api/apiMiddleware';

const persistConfig = {
	key: 'root',
	storage,
	whitelist: [ 'wizard' ]
};

const rootReducer = combineReducers({
	prospect
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(persistedReducer, applyMiddleware(apiMiddleware));
const persistor = persistStore(store);

export default { store, persistor };
