import React from 'react';
import * as PropTypes from 'prop-types';
import { HourOne } from '../../assets/Images';
import { injectIntl } from 'react-intl';
import auth from '../../auth/Auth';
import styled from '@emotion/styled';
import Colors from '../../assets/Colors';

class AuthMessage extends React.Component {
  onClickSignIn = () => {
    auth.logout('https://clients.hourone.ai/welcome');
  };

  render() {
    const { text, intl, withLoginButton } = this.props;

    return (
      <Container>
        <HourOne width={46} height={41}/>
        <Title>{text}</Title>
        {
          withLoginButton &&
          <Button onClick={this.onClickSignIn}>{intl.formatMessage({ id: 'SignIn' })}</Button>
        }
      </Container>
    );
  }
}

AuthMessage.propTypes = {
  text: PropTypes.string.isRequired,
  withLoginButton: PropTypes.bool,
};

AuthMessage.defaultProps = {
  withLoginButton: true,
};

export default injectIntl(AuthMessage);

const Container = styled.div`
	position: absolute;
	height: 95%;
	width: 95%;
	background-color: ${Colors.White};
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;

const Title = styled.div`
	font-size: 20px;
	font-weight: 900;
	font-style: italic;
	text-align: center;
	color: ${Colors.TextDefault};
	margin-top: 36px;
	margin-bottom: 40px;
`;

const Button = styled.button`
	width: 193px;
	height: 29px;
	border-radius: 3px;
	box-shadow: 0 8px 8px -5px rgba(0, 0, 0, 0.24);
	background-color: ${Colors.Orange};
	background-image: linear-gradient(-180deg, ${Colors.LightOrange}, ${Colors.Orange} 90%);
	font-size: 10px;
	line-height: 2.1;
	text-align: center;
	color: ${Colors.White};
	text-transform: uppercase;
	cursor: pointer;
	margin-top: 10px;

	&:hover {
		opacity: 0.95;
	}
`;
