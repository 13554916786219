import React from 'react';
import styled from '@emotion/styled';
import Colors from '../../assets/Colors';
import { injectIntl } from 'react-intl';
import { ViPoint } from '../../assets/Images';
import ReactGA from 'react-ga';

class Home extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			playingVideo: false
		};
		// this.props.history.push('/');
	}

	render() {
		const { intl } = this.props;
		const { playingVideo } = this.state;

		return (
			<DivContainer>
				<div
					className="clickable"
					onClick={(e) => {
						this.props.history.push('/prospects');
					}}
				>
					prospects
				</div>
			</DivContainer>
		);
	}
}

export default injectIntl(Home);

const DivContainer = styled.div`
	background-color: ${Colors.White};
	padding: 10px 18px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	box-shadow: 0 2px 8px 0 rgba(237, 237, 237, 0.5);
`;

const Container = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
	flex-shrink: 0;
	flex-basis: auto;
	position: relative;
	margin-top: 10px;
`;

const TopPart = styled.div`
	display: flex;
	flex-direction: row;
	height: 502px;
`;
const Spacer = styled.div`
	display: flex;
	flex-direction: row;
`;

const LeftSide = styled.div`
	display: flex;
	flex-direction: column;
	width: 88px;
`;

const Orange = styled.div`
	background-color: ${Colors.Orange};
	flex: 1;
`;

const DarkBlue = styled.div`
	background-color: ${Colors.DarkBlue};
	flex: 1;
`;

const Content = styled.div`
	flex: 1;
	display: flex;
	flex-direction: row;
`;

const TitleAndText = styled.div`
	display: flex;
	flex-direction: row;
	padding: 50px;
	flex: 1;
	background: rgba(0, 0, 0, 0.5);
	z-index: ${(props) => (props.playingVideo ? 0 : 10)};
	pointer-events: none;
`;
const TextSummery = styled.div`
	display: flex;
	flex-direction: column;
	flex: 3;
`;
const WelcomeTo = styled.div`
	font-size: 36px;
	color: ${Colors.LightGray};
	font-weight: 900;
	font-style: italic;
	margin-bottom: 10px;
`;

const HourOneTitle = styled.div`
	font-size: 60px;
	color: ${Colors.LightGray};
	font-weight: 900;
	font-style: italic;
	margin-bottom: 40px;
	margin-left: 12px;
`;

const Subtitle = styled.div`
	font-size: 16px;
	color: ${Colors.LightGray};
	line-height: 1.54;
	margin-bottom: 60px;
`;
const Highlights = styled.div`
	display: flex;
	flex-direction: column;
	flex: 2;
	margin-top: 30px;
	margin-left: 12px;
	font-style: italic;
`;

const SignWithText = styled.div`
	font-size: 26px;
	color: ${Colors.LightGray};
	line-height: 1.73;
	display: flex;
	flex-direction: row;
	align-items: center;
`;

const VideoPreview = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
`;
