import React from 'react';
import styled from '@emotion/styled';
import * as PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';

import ProspectsActions from '../../store/prospect/ProspectActions';

import Colors from '../../assets/Colors';
import { ArrowDown, HourOne, Profile } from '../../assets/Images';
import 'bootstrap/dist/css/bootstrap.min.css';
import ProspectActionTypes from '../../store/prospect/ProspectActionTypes';
import ProspectActions from '../../store/prospect/ProspectActions';

class Prospects extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			elem: document.documentElement
		};
	}
	componentDidMount() {
		this.props.getProspects();
	}
	onProspectClick = (index) => {
		const prospect = this.props.prospects[index];
		const { selectProspect, history } = this.props;
		selectProspect(prospect);
		history.push(`/prospect/${prospect.name}`);
	};

	render() {
		const { intl, history } = this.props;
		const pathname = history.location.pathname;
		return (
			<div>
				<h1>Prospects</h1>
				{this.props.prospects.map((e, i) => {
					return (
						<div key={i}>
							<Container
								className="clickable"
								onClick={() => {
									this.onProspectClick(i);
								}}
							>
								{e.name}
							</Container>
						</div>
					);
				})}
			</div>
		);
	}
}

Prospects.propTypes = {
	getProspects: PropTypes.func.isRequired,
	selectProspect: PropTypes.func.isRequired
};

Prospects.defaultProps = {
	prospects: []
};

const mapStateToProps = (state) => {
	// console.log(state);
	return {
		prospects: state.prospect.prospects
	};
};

const mapDispatchToProps = {
	getProspects: ProspectsActions.getProspects,
	selectProspect: ProspectActions.selectProspect
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(withRouter(Prospects)));

const Container = styled.div`
	background-color: ${Colors.White};
	padding: 10px 18px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	box-shadow: 0 2px 8px 0 rgba(237, 237, 237, 0.5);
`;
