import ProspectActionTypes from './ProspectActionTypes';

const initialState = {
	prospectsLoading: false
};

const prospectReducer = (state, action) => {
	if (!state) {
		return initialState;
	}

	const type = action.type;
	const payload = action.payload;

	switch (type) {
		case ProspectActionTypes.getProspects.PENDING: {
			return {
				...state,
				prospectsLoading: true
			};
		}
		case ProspectActionTypes.getProspects.SUCCESS: {
			return {
				...state,
				prospectsLoading: false,
				prospects: payload.data.prospects
			};
		}
		case ProspectActionTypes.getProspects.FAILURE: {
			return {
				...state,
				prospectsLoading: false
			};
		}
		case ProspectActionTypes.getProjects.PENDING: {
			return {
				...state,
				prospectsLoading: true
			};
		}
		case ProspectActionTypes.getProjects.SUCCESS: {
			return {
				...state,
				prospectsLoading: false,
				projects: payload.data.projects
			};
		}
		case ProspectActionTypes.getProjects.FAILURE: {
			return {
				...state,
				prospectsLoading: false
			};
		}
		case ProspectActionTypes.getBatches.PENDING: {
			return {
				...state,
				prospectsLoading: true
			};
		}
		case ProspectActionTypes.getBatches.SUCCESS: {
			return {
				...state,
				prospectsLoading: false,
				batches: payload.data.batches
			};
		}
		case ProspectActionTypes.getBatches.FAILURE: {
			return {
				...state,
				prospectsLoading: false
			};
		}
		case ProspectActionTypes.getTexts.PENDING: {
			return {
				...state,
				prospectsLoading: true
			};
		}
		case ProspectActionTypes.getTexts.SUCCESS: {
			return {
				...state,
				prospectsLoading: false,
				texts: payload.data.texts
			};
		}
		case ProspectActionTypes.getTexts.FAILURE: {
			return {
				...state,
				prospectsLoading: false
			};
		}
		case ProspectActionTypes.selectProspect: {
			const { prospect } = payload;
			return {
				...state,
				id: prospect._id,
				name: prospect.name
			};
		}
		case ProspectActionTypes.selectProject: {
			const { project } = payload;
			return {
				...state,
				project: project
			};
		}
		case ProspectActionTypes.selectBatch: {
			const { batch } = payload;
			return {
				...state,
				batch: batch
			};
		}
		default:
			break;
	}

	return state;
};

export default prospectReducer;
