import ProspectActionTypes from './ProspectActionTypes';
import APIConstants from '../api/APIConstants';

const getProspects = () => ({
	type: APIConstants.API_REQUEST,
	payload: {},
	url: `prospects`,
	meta: {
		method: 'GET'
	},
	baseAction: ProspectActionTypes.getProspects
});

const getProjects = (prospect) => {
	console.log(prospect);
	return {
		type: APIConstants.API_REQUEST,
		payload: {},
		url: `prospect/${prospect.name}`,
		meta: {
			method: 'GET'
		},
		baseAction: ProspectActionTypes.getProjects
	};
};

const selectProspect = (prospect) => ({
	type: ProspectActionTypes.selectProspect,
	payload: { prospect }
});

const selectProject = (project) => ({
	type: ProspectActionTypes.selectProject,
	payload: { project: project }
});

const getBatches = (prospect, project) => {
	console.log(project);
	return {
		type: APIConstants.API_REQUEST,
		payload: {},
		url: `prospect/${prospect.name}/${project.name}`,
		meta: {
			method: 'GET'
		},
		baseAction: ProspectActionTypes.getBatches
	};
};

const selectBatch = (batch) => ({
	type: ProspectActionTypes.selectBatch,
	payload: { batch: batch }
});

const getTexts = (prospect, project, batch) => {
	console.log('batch:');
	console.log(batch);
	return {
		type: APIConstants.API_REQUEST,
		payload: {},
		url: `prospect/${prospect.name}/${project.name}/${batch.name}`,
		meta: {
			method: 'GET'
		},
		baseAction: ProspectActionTypes.getTexts
	};
};

const ProspectActions = {
	getProspects,
	getProjects,
	selectProspect,
	selectProject,
	getBatches,
	selectBatch,
	getTexts
};

export default ProspectActions;
