import auth0 from 'auth0-js';
import Config from '../config/Config';

class Auth {
  constructor() {
    this.auth0Options = {
      domain: Config.auth0.domain,
      clientID: Config.auth0.clientId,
      redirectUri: Config.auth0.callbackUrl,
      audience: Config.auth0.apiUrl,
      responseType: Config.auth0.responseType,
      scope: Config.auth0.scope
    };

    this.auth0 = new auth0.WebAuth(this.auth0Options);

    this.accessToken = null;
    this.idToken = null;
    this.expiresAt = 0;
    this.userProfile = null;
    this.authenticationError = null;
  }

  login = (returnToUrl) => {
    if (returnToUrl) {
      this.auth0.authorize({
        state: returnToUrl
      });
    } else {
      this.auth0.authorize();
    }
  };

  handleAuthentication = (callback) => {
    this.auth0.parseHash((err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        this.setSession(authResult);
        callback(authResult.state, null);
      } else if (err) {
        // history.replace('/unauthorized');
        this.authenticationError = err;
        callback(null, err);
      }
    });
  };

  getAccessToken = () => {
    return this.accessToken;
  };

  getIdToken = () => {
    return this.idToken;
  };

  getAuthenticationError = () => {
    return this.authenticationError;
  };

  setSession = (authResult) => {
    // Set isLoggedIn flag in localStorage
    localStorage.setItem('isLoggedIn', 'true');

    // Set the time that the access token will expire at
    let expiresAt = authResult.expiresIn * 1000 + new Date().getTime();
    this.accessToken = authResult.accessToken;
    this.idToken = authResult.idToken;
    this.expiresAt = expiresAt;

    this.getProfile();
  };

  renewSession = (cb, returnToUrl) => {
    this.auth0.checkSession({}, (err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        this.renewingSession = false;
        this.setSession(authResult);
        cb(true);
      } else if (err) {
        this.renewingSession = false;
        this.login(returnToUrl);
      }
    });
  };

  getProfile = (cb) => {
    this.auth0.client.userInfo(this.accessToken, (err, profile) => {
      if (profile) {
        this.userProfile = profile;
      }
      if (cb) {
        cb(err, profile);
      }
    });
  };

  logout = (withRedirect) => {
    // Remove tokens and expiry time
    this.accessToken = null;
    this.idToken = null;
    this.expiresAt = 0;

    // Remove user profile
    this.userProfile = null;

    // Remove isLoggedIn flag from localStorage
    localStorage.removeItem('isLoggedIn');

    this.auth0.logout({
      returnTo: withRedirect ? withRedirect : Config.auth0.logoutRedirectTo,
    });
  };

  isAuthenticated = () => {
    // Check whether the current time is past the
    // access token's expiry time
    let expiresAt = this.expiresAt;
    return new Date().getTime() < expiresAt;
  };
}

// We're exporting the instance of this object to be shared across the app,
// so we won't need to pass it as props / context etc.
const auth = new Auth();
export default auth;
