import React from "react";
import styled from "@emotion/styled";
import * as PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import { withRouter } from "react-router";
import { connect } from "react-redux";

import ProspectsActions from "../../store/prospect/ProspectActions";

import Colors from "../../assets/Colors";
import { ArrowDown, HourOne, Profile } from "../../assets/Images";
import "bootstrap/dist/css/bootstrap.min.css";

class Projects extends React.Component {
    constructor(props) {
        super(props);
        // const { match: { params } } = this.props;
        // console.log(params);
        this.state = {
            elem: document.documentElement,
        };
    }
    componentDidMount() {
        this.props.getProjects(this.props.prospect);
    }
    onProjectClick = (index) => {
        const prospect = this.props.prospect;
        const project = this.props.prospect.projects[index];
        const { selectProject, history } = this.props;
        selectProject(project);
        history.push(`/prospect/${prospect.name}/${project.name}`);
    };
    render() {
        const { intl, history } = this.props;
        const {
            match: { params },
        } = this.props;

        const pathname = history.location.pathname;
        return (
            <div>
                <h1>Prospect: {params.prospect}</h1>
                {this.props.projects.map((e, i) => {
                    return (
                        <div key={i}>
                            <Container
                                className="clickable"
                                onClick={() => {
                                    this.onProjectClick(i);
                                }}
                            >
                                {e.name}
                            </Container>
                        </div>
                    );
                })}
            </div>
        );
    }
}

Projects.propTypes = {
    getProjects: PropTypes.func.isRequired,
    selectProject: PropTypes.func.isRequired,
};

Projects.defaultProps = {
    projects: [],
};

const mapStateToProps = (state) => {
    return {
        projects: state.prospect.projects,
        prospect: state.prospect,
        prospects: state.prospect.prospects,
    };
};

const mapDispatchToProps = {
    getProjects: ProspectsActions.getProjects,
    selectProject: ProspectsActions.selectProject,
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(withRouter(Projects)));

const Container = styled.div`
    background-color: ${Colors.White};
    padding: 10px 18px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 2px 8px 0 rgba(237, 237, 237, 0.5);
`;
