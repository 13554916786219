import React from 'react';
import styled from '@emotion/styled';
import * as PropTypes from 'prop-types';
import Colors from '../../assets/Colors';

const Link = props => (
  <Container
    onClick={props.onClick}
    dark={props.dark}
    style={{...(props.style || {})}}
  >
    {props.text}
  </Container>
);

Link.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  dark: PropTypes.bool,
};

Link.defaultProps = {
  dark: false,
};

export default Link;

const Container = styled.a`
  height: 25px;
  font-size: 14px;
  line-height: 1.5;
  color: ${props => props.dark === true ? Colors.DarkBlue : '#a1aeb7'};
  cursor: pointer;
  text-decoration: underline;
  
  &:hover {
    color: ${props => props.dark === true ? '#a1aeb7' : Colors.DarkBlue};
  }
`;
