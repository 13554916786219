import React from 'react';
import styled from '@emotion/styled';
import * as PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import Colors from '../../assets/Colors';
import auth from '../../auth/Auth';
import { ArrowDown, HourOne, Profile } from '../../assets/Images';
import Link from '../common/Link';

class TopBar extends React.Component {
	onClickSignOut = () => {
		auth.logout();
	};

	render() {
		const { intl, history } = this.props;

		const pathname = history.location.pathname;

		return (
			<Container>
				<LeftSide>
					<Link
						text={intl.formatMessage({ id: 'Back' })}
						onClick={() => history.goBack()}
						style={{ marginTop: '20px' }}
					/>
				</LeftSide>
				<RightSide>
					<div src={auth.userProfile ? auth.userProfile.picture : ''}>
						{auth.userProfile ? auth.userProfile.nickname : ''}
					</div>
					<img width={32} height={32} src={auth.userProfile ? auth.userProfile.picture : ''} />

					<button onClick={this.onClickSignOut}>{intl.formatMessage({ id: 'SignOut' })}</button>
				</RightSide>
			</Container>
		);
	}
}

TopBar.propTypes = {
	// toggleMyProfile: PropTypes.func.isRequired,
	// clearCurrentProject: PropTypes.func.isRequired
};

TopBar.defaultProps = {};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
	// toggleMyProfile: UserActions.toggleMyProfile,
	// clearCurrentProject: WizardActions.clearCurrentProject
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(withRouter(TopBar)));

const Container = styled.div`
	background-color: ${Colors.White};
	padding: 10px 18px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	box-shadow: 0 2px 8px 0 rgba(237, 237, 237, 0.5);
`;

const LeftSide = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
`;

const RightSide = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
`;

const Logo = styled.div`
	margin-right: 74px;
	cursor: pointer;
	&:hover {
		opacity: 0.6;
	}
`;

const Option = styled.a`
	font-size: 13px;
	font-weight: 400;
	color: ${Colors.TextDefault};
	margin-right: 39px;
	text-transform: uppercase;
	cursor: pointer;
	flex-shrink: 0;
	text-decoration: ${(props) => (props.active === true ? 'underline' : 'normal')};
	display: inline-block;
	text-align: center;

	&:hover {
		opacity: 0.6;
	}

	&:after {
		display: block;
		content: attr(title);
		font-weight: bold;
		height: 0;
		overflow: hidden;
		visibility: hidden;
	}
`;

const MakeVideo = styled.button`
	width: 166px;
	height: 32px;
	border-radius: 3px;
	box-shadow: 0 8px 8px -5px rgba(0, 0, 0, 0.16);
	background-color: ${Colors.Orange};
	background-image: linear-gradient(-180deg, ${Colors.LightOrange}, ${Colors.Orange} 90%);
	opacity: 1;
	margin-right: 26px;
	cursor: pointer;
	font-size: 15px;
	font-weight: 700;
	color: ${Colors.White};

	&:hover {
		opacity: 0.74;
	}
`;

const UserProfile = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	cursor: pointer;
	&:hover {
		opacity: 0.74;
	}
`;
