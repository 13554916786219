const emailRegex = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
const driveRegex = /https:\/\/drive\.google\.com\/uc\?[^\s]*|https:\/\/drive\.google\.com\/open\?[^\s]*|https:\/\/drive\.google\.com\/[^\s]*\/file\/[^\s]*/;

const validateEmail = email => emailRegex.test(email);

const validateGoogleDriveUrl = link => driveRegex.test(link);

const isNullOrEmpty = function(str) {
  return (
    str === undefined ||
    str === null ||
    typeof str !== 'string' ||
    str.trim() === ''
  );
};

const StringUtils = {
  validateEmail,
  validateGoogleDriveUrl,
  isNullOrEmpty,
};

export default StringUtils;
