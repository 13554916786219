import React from 'react';
import auth from './Auth';
import styled from '@emotion/styled';
import FullScreenLoader from '../components/loader/FullScreenLoader';
import StringUtils from '../utils/StringUtils';
import AuthMessage from '../components/authentication/AuthMessage';

class Callback extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      authError: null,
      returnTo: null,
    };
  }

  componentDidMount() {
    this.handleAuthentication();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.loading !== true && prevState.loading === true) {
      if (this.state.authError === null) {
        // We connected successfully
        if (StringUtils.isNullOrEmpty(this.state.returnTo)) {
          this.props.history.push('home');
        } else {
          window.location = this.state.returnTo;
        }
      }
    }
  }

  handleAuthentication = () => {
    if (/access_token|id_token|error/.test(this.props.location.hash)) {
      auth.handleAuthentication((state, authError) => {
        if (authError !== null) {
          this.setState({
            authError,
            loading: false,
          });
        } else {
          this.setState({
            loading: false,
            returnTo: state,
          });
        }
      });
    }
  };

  renderAuthenticationStatus = () => {
    const { loading, authError } = this.state;
    if (loading !== true) {
      if (authError !== null) {
        return (
          <AuthMessage
            text={authError.errorDescription}
          />
        );
      }
    }

    return (
      <FullScreenLoader visible={this.state.loading} />
    );
  };

  render() {
    return (
      <Container>
        {this.renderAuthenticationStatus()}
      </Container>
    )
  }
}

export default Callback;

const Container = styled.div`
  padding: 20px;
`;


