let BASE_URL = 'https://integration.hourone.ai/api/';
if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
	BASE_URL = 'http://localhost:5000/api/';
}

const API_REQUEST = 'API_REQUEST';

const createAPIRequestAction = (actionType) => ({
	PENDING: `${actionType}_PENDING`,
	SUCCESS: `${actionType}_SUCCESS`,
	FAILURE: `${actionType}_FAILURE`,
	BASE: actionType
});

const APIConstants = {
	BASE_URL,
	API_REQUEST,
	createAPIRequestAction
};

export default APIConstants;
