import React from 'react';
import styled from '@emotion/styled';
import * as PropTypes from 'prop-types';
import Colors from '../../assets/Colors';
import { Loader } from '../../assets/Images';

class FullScreenLoader extends React.Component {
	render() {
		if (this.props.visible !== true) {
			return null;
		}

		return (
			<Container>
				<Loader width={100} height={100} />
			</Container>
		);
	}
}

FullScreenLoader.propTypes = {
	visible: PropTypes.bool.isRequired
};

FullScreenLoader.defaultProps = {};

export default FullScreenLoader;

const Container = styled.div`
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: ${Colors.MidGray};
	display: flex;
	justify-content: center;
	align-items: center;
`;
