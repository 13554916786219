import React from 'react';
import * as PropTypes from 'prop-types';
import { HourOne } from '../../assets/Images';
import { injectIntl } from 'react-intl';
import auth from '../../auth/Auth';
import styled from '@emotion/styled';
import Colors from '../../assets/Colors';

class ErrorPage extends React.Component {
	onClickSignIn = () => {
		auth.logout('https://clients.hourone.ai/welcome');
	};

	render() {
		const { intl } = this.props;

		return (
			<Container>
				<HourOne width={46} height={41} />
				<Title>{intl.formatMessage({ id: 'Oops' })}</Title>
				<Subtitle>
					{intl.formatMessage({ id: 'ErrorMessage' })}
					<Email href={`mailto:${intl.formatMessage({ id: 'CompanyEmail' })}`}>
						{intl.formatMessage({ id: 'CompanyEmail' })}
					</Email>
				</Subtitle>

				<Button onClick={this.onClickSignIn}>{intl.formatMessage({ id: 'SignIn' })}</Button>
			</Container>
		);
	}
}

ErrorPage.propTypes = {
	ErrorMessage: PropTypes.string.isRequired
};

ErrorPage.defaultProps = {};

export default injectIntl(ErrorPage);

const Container = styled.div`
	position: absolute;
	height: 95%;
	width: 95%;
	background-color: ${Colors.White};
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;

const Title = styled.div`
	font-size: 45px;
	font-weight: 900;
	font-style: italic;
	text-align: center;
	color: ${Colors.TextDefault};
	margin-top: 36px;
`;
const Subtitle = styled.div`
	font-size: 20px;
	font-weight: 900;
	font-style: italic;
	text-align: center;
	color: ${Colors.TextDefault};
	margin-bottom: 40px;
`;

const Button = styled.button`
	width: 193px;
	height: 29px;
	border-radius: 3px;
	box-shadow: 0 8px 8px -5px rgba(0, 0, 0, 0.24);
	background-color: ${Colors.Orange};
	background-image: linear-gradient(-180deg, ${Colors.LightOrange}, ${Colors.Orange} 90%);
	font-size: 10px;
	line-height: 2.1;
	text-align: center;
	color: ${Colors.White};
	text-transform: uppercase;
	cursor: pointer;
	margin-top: 10px;

	&:hover {
		opacity: 0.95;
	}
`;
const Email = styled.a`
	&:hover {
		opacity: 0.95;
	}
`;
